import React from "react";

export default function DDplusIcon({
  dColor = "#404040",
  width = 64,
  height = 72,
}: {
  dColor?: string;
  width?: number | string;
  height?: number | string;
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 94 83" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M79.1002 67.9V82.9H68.8002V67.9H54.2002V57.5H68.7002V42.5H79.0002V57.5H93.6002V67.9H79.1002Z"
          fill="url(#paint0_linear_ddplus)"
        />
        <path
          d="M41.5 41.5C41.5 30.1 32.3 20.8 20.8 20.7C20.8 20.7 20.8 20.7 20.7 20.7H0V62.2H20.7C32.2 62.2 41.5 52.9 41.5 41.5ZM10.4 41.5V31.1H20.8C26.5 30.9 31.3 35.4 31.5 41.1C31.7 46.8 27.2 51.6 21.5 51.8C21.3 51.8 21.1 51.8 20.9 51.8H10.4V41.5Z"
          fill={dColor}
        />
        <path
          d="M20.7 0H0V10.4H20.7C37.9 10.1 52.1 23.8 52.4 40.9C52.7 58.1 39 72.3 21.9 72.6C21.5 72.6 21.2 72.6 20.8 72.6H0V83H20.7C32.1 83 43.1 78.3 50.9 69.9L51.1 54.6H60.1C67.3 32.9 55.6 9.4 33.9 2.1C29.7 0.7 25.2 0 20.7 0Z"
          fill={dColor}
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_ddplus"
          x1="85.9922"
          y1="73.8678"
          x2="67.0822"
          y2="56.4378"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1C058" />
          <stop offset="0.32" stopColor="#F4DD7F" />
          <stop offset="0.49" stopColor="#FFEF97" />
          <stop offset="0.72" stopColor="#EFD97C" />
          <stop offset="1" stopColor="#D9B955" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="93.5" height="83" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
