import { t } from "@lingui/macro";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext, useEffect, useMemo, useState } from "react";
import DDplusIcon from "../../icons/DDplusIcon";
import { useStateWithLocalStorage } from "../../hooks/useStateWithLocalStorage/useStateWithLocalStorage";
import { FeaturesCarousel } from "../../ecosystems/new-member/components/FeaturesCarousel";
import { SuccessStoriesCarousel } from "../../ecosystems/new-member/components/SuccessStoriesCarousel";
import { useAnonymousMealPlanGenerator } from "../../ecosystems/onboarding/hooks/useAnonymousMealPlanGenerator";
import { OnboardingGenderPage } from "../../ecosystems/onboarding/pages";
import { SignUpPage, SignUpStep, WelcomePage } from "../../pages";
import { Locale } from "../../types/Locale";
import { weekdays } from "../../utils/date";
import Button from "../Button/Button";
import { i18nContext } from "../I18nProvider/I18nProvider";
import EmptyResponsiveImage from "../Image/EmptyResponsiveImage";
import { useNavigate } from "../Link/Link";
import { LoginContext } from "../LoginProvider/LoginProvider";
import MealGridPreview from "../MealPlanCard/PreviewMealPlanCard/MealGridPreview";
import PreviewMealPlanCard, { Label, Wrapper } from "../MealPlanCard/PreviewMealPlanCard/PreviewMealPlanCard";
import { pageContext } from "../PageProvider/PageProvider";
import { SEOReactHelmet } from "../SEO/SEOReactHelmet";
import TrustPilot from "../TrustPilot/TrustPilot";

const MealPlanPreviewPage = () => {
  const [generateCountRetry, setGenerateCountRetry] = useState(3);
  const { user, loading: isUserLoading, premium } = useContext(LoginContext);
  const { locale } = useContext(pageContext);
  const navigate = useNavigate();
  const tt = useTranslations();
  const [, setAnonymousMealPlan] = useStateWithLocalStorage<string>("dd/onboarding/anonymous-meal-plan", "");

  const {
    generate: generateAnonymousMealPlan,
    anonymousMealPlan,
    error,
    saving,
  } = useAnonymousMealPlanGenerator();

  const isBusy = useMemo(() => isUserLoading || saving, [isUserLoading, saving]);

  useEffect(() => {
    if (!saving && typeof anonymousMealPlan !== "undefined") {
      setAnonymousMealPlan(JSON.stringify(anonymousMealPlan));
    } else if (!saving && error && generateCountRetry > 0) {
      // generation failed, let's try a few times
      setGenerateCountRetry((count) => count - 1);
      setTimeout(() => {
        generateAnonymousMealPlan();
      }, 250);
    }
  }, [anonymousMealPlan, saving, error, setAnonymousMealPlan, generateCountRetry, generateAnonymousMealPlan]);

  useEffect(() => {
    if (!isUserLoading) {
      generateAnonymousMealPlan();
    }
  }, [isUserLoading, generateAnonymousMealPlan]);

  const imageData = useStaticQuery(graphql`
    query {
      webQuizImageEN: file(relativePath: { eq: "web-quiz-image.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 768)
        }
      }
      webQuizImageSE: file(relativePath: { eq: "web-quiz-image-se.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 768)
        }
      }
      webQuizImageES: file(relativePath: { eq: "web-quiz-image-es.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 768)
        }
      }
    }
  `);

  return (
    <>
      <SEOReactHelmet noindex title="Diet Doctor - Meal Plan Preview" lang={locale as string} />

      <div className="mx-auto mt-12 md:mt-16 flex flex-col items-center">
        <DDplusIcon />
      </div>

      {/* Error state go back to survey */}
      {generateCountRetry === 0 && error && !isBusy && (
        <div className="flex flex-col items-center text-center max-w-7xl mx-auto px-4">
          <h2 className="text-black text-3xl pt-2 mb-8 text-center">{tt.error.title}</h2>
          <p>{tt.error.message}</p>
          <Button
            style={{ width: "300px" }}
            className="bg-green"
            onClick={() =>
              navigate({
                to: OnboardingGenderPage,
              })
            }
          >
            {tt.error.redoButton}
          </Button>
        </div>
      )}

      {/* Loading state */}
      {isBusy && (
        <div className="flex flex-col items-center text-center max-w-7xl mx-auto px-4">
          <h2 className="text-black text-3xl pt-2 mb-4">{tt.loadingMessage}</h2>
          <MealGridPreview animation="loading">
            {weekdays.map((day, index) => (
              <Wrapper key={index}>
                <Label>{tt.weekdayShort(day)}</Label>
                <EmptyResponsiveImage width={150} height={225} />
              </Wrapper>
            ))}
          </MealGridPreview>
        </div>
      )}

      {/* Success state */}
      {typeof anonymousMealPlan !== "undefined" && !isBusy && (
        <>
          <div className="flex flex-col items-center text-center max-w-7xl mx-auto px-4">
            <h2 className="text-black text-3xl pt-2 mb-4">{tt.heading}</h2>
            <PreviewMealPlanCard mealPlan={anonymousMealPlan} errorMessage={tt.error.title} />

            {/* The user has no premium subscription -> to payment */}
            {user && !premium && !isBusy && (
              <Button
                className="mx-auto !w-72 max-w-xs mt-8 bg-green"
                onClick={() =>
                  navigate({
                    to: SignUpPage,
                    params: {
                      step: SignUpStep.PLAN,
                      content: "onboarding",
                    },
                  })
                }
              >
                {tt.nonPremiumUserButton}
              </Button>
            )}

            {/* The user has a premium subscription -> to the home page */}
            {user && premium && !isBusy && (
              <Button
                className="mx-auto !w-72 max-w-xs mt-8 bg-green"
                onClick={() =>
                  navigate({
                    to: WelcomePage,
                    query: {
                      content: "onboarding",
                    },
                  })
                }
              >
                {tt.nonPremiumUserButton}
              </Button>
            )}

            {/* The user has no account -> to the create sign up page */}
            {!user && !isBusy && (
              <Button
                className="mx-auto !w-72 max-w-xs mt-8 bg-green"
                onClick={() =>
                  navigate({
                    to: SignUpPage,
                    params: {
                      step: SignUpStep.ACCOUNT,
                      content: "onboarding",
                    },
                  })
                }
              >
                {tt.newUserButton}
              </Button>
            )}
          </div>

          <p className="m-0 text-center mx-auto mt-4 max-w-3xl px-2">
            {tt.goalsMessage}
            <br></br>
            {tt.needsMessage}
          </p>

          <p className="text-center text-black text-sm max-w-sm px-2 mx-auto mb-12">
            {tt.freeTrialDisclaimerMessage}
          </p>

          <div className="bg-green-500 w-full">
            <div className="flex flex-col md:flex-row items-center max-w-6xl mx-auto md:space-x-4 md:px-6 md:pt-2">
              <div className="w-full md:w-1/2 text-center md:text-left mt-14 md:mt-0">
                <h3 className="display-xxs md:display-s m-0 text-[#37664E] md:text-dark-blue">
                  {tt.routineHeading}
                </h3>
                <p className="body-l md:body-xl m-0 dark-blue max-w-sm px-2 md:px-0 md:pr-3 my-5 mb-7 mx-auto md:mx-0">
                  {tt.routineText}
                </p>
                <div className="flex flex-row justify-center md:justify-start">
                  <Button
                    className="!w-72 max-w-xs m-0 p-0 bg-green"
                    color="green"
                    onClick={() =>
                      navigate({
                        to: SignUpPage,
                        params: {
                          step: user ? SignUpStep.PLAN : SignUpStep.ACCOUNT,
                          content: "onboarding",
                        },
                      })
                    }
                  >
                    {tt.newUserButton}
                  </Button>
                </div>
              </div>

              <div className="w-full md:w-1/2 overflow-hidden">
                {locale === Locale.EN && (
                  <GatsbyImage
                    image={imageData.webQuizImageEN?.childImageSharp?.gatsbyImageData}
                    className="w-full h-full"
                    objectFit="cover"
                    alt=""
                  />
                )}
                {locale === Locale.SV && (
                  <GatsbyImage
                    image={imageData.webQuizImageSE?.childImageSharp?.gatsbyImageData}
                    className="w-full h-full"
                    objectFit="cover"
                    alt=""
                  />
                )}
                {locale === Locale.ES && (
                  <GatsbyImage
                    image={imageData.webQuizImageES?.childImageSharp?.gatsbyImageData}
                    className="w-full h-full"
                    objectFit="cover"
                    alt=""
                  />
                )}
              </div>
            </div>
          </div>

          <section className="bg-white py-14">
            <div className="max-w-screen-xl mx-auto">
              <h2 className="display-xxs md:display-s text-center m-0 mb-6 md:mb-8 px-10">{tt.youAlsoGet}</h2>

              <div>
                <FeaturesCarousel />
              </div>
              <div className="mt-10 md:flex justify-center hidden">
                <Button
                  className="mx-auto !w-72 max-w-xs bg-green mt-4"
                  color="green"
                  onClick={() =>
                    navigate({
                      to: SignUpPage,
                      params: {
                        step: user ? SignUpStep.PLAN : SignUpStep.ACCOUNT,
                        content: "onboarding",
                      },
                    })
                  }
                >
                  {tt.newUserButton}
                </Button>
              </div>
            </div>
          </section>

          <section className="bg-green-500">
            <div className="py-12 px-4 md:px-12 max-w-fit mx-auto">
              <h2 className="display-xxs md:display-s text-center m-0 mb-6 md:mb-8 px-10">
                {tt.joinSuccessfulMembers}
              </h2>
              <div className="mx-auto" style={{ maxWidth: "600px" }}>
                <SuccessStoriesCarousel />
              </div>
            </div>
          </section>

          <div className="bg-green-500 text-center pb-12">
            <TrustPilot className="w-full mx-auto" />

            <Button
              className="mx-auto !w-72 max-w-xs bg-green mt-8"
              color="green"
              onClick={() =>
                navigate({
                  to: SignUpPage,
                  params: {
                    step: user ? SignUpStep.PLAN : SignUpStep.ACCOUNT,
                    content: "onboarding",
                  },
                })
              }
            >
              {tt.newUserButton}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

// ts-prune-ignore-next
export default MealPlanPreviewPage;

function useTranslations() {
  const { translations } = useContext(i18nContext);

  return {
    weekdayShort: translations.weekdayShort,

    heading: t({
      id: "MealPlanPreviewPage.heading",
      message: "It's done!",
    }),

    nonPremiumUserButton: t({
      id: "MealPlanPreviewPage.nonPremiumUserButton",
      message: "Continue",
    }),
    newUserButton: t({
      id: "MealPlanPreviewPage.newUserButton",
      message: "Start free trial",
    }),
    freeTrialDisclaimerMessage: t({
      id: "MealPlanPreviewPage.freeTrialDisclaimerMessage",
      message: "1 month FREE. Cancel within 30 days and pay nothing.",
    }),

    error: {
      title: t({
        id: "MealPlanPreviewPage.error.title",
        message: "Oops! We can't accommodate all your preferences yet.",
      }),
      message: t({
        id: "MealPlanPreviewPage.error.message",
        message: "Can you change any? If so, we'll try again to generate your meal plan.",
      }),
      redoButton: t({
        id: "MealPlanPreviewPage.error.button",
        message: "Try again",
        comment: "User will click this button to redo the personalization survey.",
      }),
    },

    loadingMessage: t({
      id: "MealPlanPreviewPage.loadingMessage",
      message: "Your meal plan is cooking!",
      comment: "Message shown while meal plan generation is undergoing.",
    }),

    goalsMessage: t({
      id: "MealPlanPreviewPage.goalsMessage",
      message: "Reach your goals with a meal plan that fits your net carb, protein and caloric needs.",
    }),
    needsMessage: t({
      id: "MealPlanPreviewPage.needsMessage",
      message: "We tailor it to match your needs, but you can swap, change, and rearrange to suit your style.",
    }),

    routineHeading: t({
      id: "MealPlanPreviewPage.routineHeading",
      message: "30 day free trial",
    }),
    routineText: t({
      id: "MealPlanPreviewPage.routineText",
      message:
        "Delicious recipes, meal plans made simple, insightful videos, weight loss advice from medical experts & more.",
    }),

    youAlsoGet: t({
      id: "MealPlanPreviewPage.youAlsoGet",
      message: "You’ll also get",
    }),
    joinSuccessfulMembers: t({
      id: "MealPlanPreviewPage.joinSuccessfulMembers",
      message: "Join thousands of successful members",
    }),
  };
}
