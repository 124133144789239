import React, { useContext } from "react";
import ResponsiveImage from "../../Image/ResponsiveImage";
import styled from "@emotion/styled";
import { default as colors } from "../../../utils/colors.json";
import MealGridPreview from "./MealGridPreview";
import { MOBILE_MD } from "../../../utils/breakpoints";
import { GenerateMealPlan_generateMealplan } from "../../../types/generateMealplan";
import { i18nContext } from "../../I18nProvider/I18nProvider";
import { DayKey } from "../../../types/MealPlan";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Label = styled.span`
  position: absolute;
  left: 0;
  padding: 14px;
  font-size: 1.5rem;
  color: ${colors.GREY_DARKER};
  opacity: 0.5;
  @media (max-width: ${MOBILE_MD}) {
    border-radius: 8px;
    font-size: 16px;
    padding: 8px;
    border-radius: 8px 8px 0 0;
    line-height: 1;
  }
`;

const PreviewMealPlanCard = ({
  mealPlan,
  errorMessage,
}: {
  mealPlan: GenerateMealPlan_generateMealplan;
  errorMessage: string;
}) => {
  const {
    translations: { weekdayShort },
  } = useContext(i18nContext);
  const schedule = mealPlan.schedule;

  console.log(mealPlan);
  return (
    <>
      <MealGridPreview animation="presenting">
        {schedule ? (
          schedule.map((mealPlanDay, idx) => (
            <Wrapper key={`${mealPlanDay.dinner![0]!.title!}-${idx}`}>
              <Label>{weekdayShort(mealPlanDay.name! as DayKey)}</Label>

              <ResponsiveImage
                path={mealPlanDay.dinner![0]!.images!.vt}
                alt={mealPlanDay.dinner![0]!.title!}
                width={150}
                height={225}
                sizes="(max-width: 319px) 33vw, (min-width: 1200px) 300px, 20vw"
                dimensions={[
                  { width: 150, height: 225 },
                  { width: 200, height: 300 },
                  { width: 300, height: 450 },
                ]}
                defaultDimension={{ width: 150, height: 225 }}
                rounded
              />
            </Wrapper>
          ))
        ) : (
          <p>{errorMessage}</p>
        )}
      </MealGridPreview>
    </>
  );
};

export default PreviewMealPlanCard;
