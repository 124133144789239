import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { Locale } from "../../types/Locale";
import { pageContext } from "../PageProvider/PageProvider";

export default function TrustPilot({ style, className }: { style?: React.CSSProperties; className?: string }) {
  const { locale } = useContext(pageContext);
  const { widgetLocale, reviewsLocale, href } = trustpilotSettings[locale];

  return (
    <>
      <Helmet>
        <script
          id="trustpilot-widget-script"
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        />
      </Helmet>
      <div
        className={`trustpilot-widget ${className ? className : ""}`}
        data-locale={widgetLocale}
        data-template-id="54ad5defc6454f065c28af8b"
        data-businessunit-id="5bbf69969bca1d00019ab12c"
        data-style-height="240px"
        data-style-width="100%"
        data-theme="light"
        data-review-languages={reviewsLocale}
        data-stars="5"
        style={style}
      >
        <a href={href} target="_blank" rel="noopener noreferrer">
          Trustpilot
        </a>
      </div>
    </>
  );
}

const trustpilotSettings: Record<Locale, { widgetLocale: string; reviewsLocale: string; href: string }> = {
  [Locale.EN]: {
    widgetLocale: "en-US",
    reviewsLocale: "en",
    href: "https://www.trustpilot.com/review/dietdoctor.com",
  },
  [Locale.ES]: {
    widgetLocale: "es-ES",
    reviewsLocale: "es",
    href: "https://es.trustpilot.com/review/dietdoctor.com",
  },
  [Locale.SV]: {
    widgetLocale: "sv-SE",
    reviewsLocale: "sv",
    href: "https://se.trustpilot.com/review/dietdoctor.com",
  },
  [Locale.ZU]: {
    widgetLocale: "en-US",
    reviewsLocale: "en",
    href: "https://www.trustpilot.com/review/dietdoctor.com",
  },
};
