import styled from "@emotion/styled";
import { MOBILE_MD } from "../../../utils/breakpoints";
import { MealGrid } from "../MealPlanCard";

const MealGridPreview = styled(MealGrid)`
  > div {
    &::before {
      position: absolute;
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 15px;
      background: ${(props: { animation: string }) => props.animation === "presenting" && "#f3f3f3"};
      animation: ${(props: { animation: string }) =>
        props.animation === "presenting"
          ? `
              presentationWave 3.5s forwards
            `
          : props.animation === "loading"
          ? `
              loadingWave 3.5s ease-in-out infinite
            `
          : "none"};
      @media (max-width: ${MOBILE_MD}) {
        border-radius: 8px;
      }
    }
    @media (max-width: ${MOBILE_MD}) {
      border-radius: 8px;
    }

    :nth-of-type(1) {
      &::before {
        animation-delay: 0.5s;
      }
    }
    :nth-of-type(2) {
      &::before {
        animation-delay: 1s;
      }
    }
    :nth-of-type(3) {
      &::before {
        animation-delay: 1.5s;
      }
    }
    :nth-of-type(4) {
      &::before {
        animation-delay: 2s;
      }
    }
    :nth-of-type(5) {
      &::before {
        animation-delay: 2.5s;
      }
    }
    :nth-of-type(6) {
      &::before {
        animation-delay: 3s;
      }
    }
    :nth-of-type(7) {
      &::before {
        animation-delay: 3.5s;
      }
    }
  }

  @keyframes presentationWave {
    80%,
    100% {
      opacity: 0;
    }
  }

  @keyframes loadingWave {
    50%,
    75% {
      background: #9d9999;
    }

    80%,
    100% {
      opacity: 0;
    }
  }
`;
export default MealGridPreview;
