import { useEffect, useState } from "react";
import { isDOMAvailable } from "../../utils/ssr";

type StorageType = "local" | "session";

export function useStateWithLocalStorage<T>(
  key: string,
  defaultValue: T,
  storageType: StorageType = "local"
): [T, (value: T) => void] {
  const storage = getStorage(storageType);
  const initialValueStr = storage.getItem(key);
  let initialValue: T;
  try {
    initialValue = initialValueStr ? (JSON.parse(initialValueStr) as T) : defaultValue;
  } catch (e) {
    initialValue = defaultValue;
  }
  const [value, setValueOriginal] = useState<T>(initialValue);

  useEffect(() => {
    storage.setItem(key, JSON.stringify(value));
  }, [value, key, storage]);

  return [value, setValueOriginal];
}

const mockStorage: Storage = {
  length: 0,
  clear: () => undefined,
  getItem: () => null,
  key: () => null,
  removeItem: () => undefined,
  setItem: () => undefined,
};

const getStorage = (storageType: StorageType) => {
  if (!isDOMAvailable()) {
    return mockStorage;
  }
  return storageType === "local" ? window.localStorage : window.sessionStorage;
};
